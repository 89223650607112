import { useState, useEffect } from 'react'
export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)
  const observer = (typeof window === 'undefined')
    ? { observe: () => {}, }
    : new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(entry.isIntersecting)
      }
    })

  useEffect(() => {
    observer.observe(ref.current)
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}
