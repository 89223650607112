import React, { useRef } from 'react'
import useOnScreen from '../../utility/useOnScreen'
import { Suspense, lazy } from '@uploadcare/client-suspense'
import DefaultMapMarker from '../atoms/DefaultMapMarker'
const GoogleMap = lazy(() => import('../molecules/GoogleMap'))
const SurroundingsSection = lazy(() => import('../molecules/SurroundingsSection'))

const IndexBottomSection = props => {
  const ref = useRef()
  const isSectionVisible = useOnScreen(ref)
  const geoCodes = [
    { lat: 49.9993812, lng: 19.4086305, },
    { lat: 49.8841055, lng: 19.4777605, },
    { lat: 50.0618971, lng: 19.9345672, },
    { lat: 49.9690762, lng: 20.4277796, },
    { lat: 49.9823327, lng: 20.0579787, },
    { lat: 50.0314994, lng: 19.1860073, }
  ]
  return (
    <>
    <div ref={ref}></div>
     <Suspense fallback={<div>...Loading</div>}>
        {isSectionVisible &&
          <>
            <SurroundingsSection />
            <GoogleMap
              mapCenter={{ lat: 49.881009, lng: 20.085285, }}
              zoom={9}
            >
            {geoCodes.map(place => (
              <DefaultMapMarker
                key={place.lat}
                {...place}
              />
            ))}

            </GoogleMap>
          </>
        }
      </Suspense>
    </>
  )
}

IndexBottomSection.propTypes = {

}

export default IndexBottomSection
