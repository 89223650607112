import React from 'react'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel'

const CustomCarousel = (props) => {
  const classes = useStyles()
  const { translations, } = props

  return (
    <>
    <div className={classes.displayDescktop}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={600}
          totalSlides={3}
          style={{ position: 'relative', }}
        >
          <Slider >
            <Slide index={0}>
                <div className={`slide_1 ${classes.slide}`} >
                  <h1 style={{ paddingTop: '0.5em', }} className={classes.title}>{translations.slide_1.title}</h1>
                  <h5 style={{ paddingTop: '0em', }}>{translations.slide_1.sub_title}</h5>
                </div>
              </Slide>
              <Slide index={1}>
                <div className={`slide_2 ${classes.slide}`} >
                  <h5>{translations.slide_2.sub_title}</h5>
                  <h4 className={classes.title}>{translations.slide_2.title}</h4>
                </div>
              </Slide>
              <Slide index={2}>
                <div className={`slide_3 ${classes.slide}`} >
                  <h5>{translations.slide_3.sub_title}</h5>
                  <h4 className={classes.title}>{translations.slide_3.title}</h4>
                </div>
              </Slide>
          </Slider>
          <div className={classes.dotContainer}>
            <Dot slide={0} className={`slide_1 ${classes.dot}`} />
            <Dot slide={1} className={`slide_2 ${classes.dot}`} />
            <Dot slide={2} className={`slide_3 ${classes.dot}`} />
          </div>
        </CarouselProvider>
      </div>
      <div className={classes.displayMobile}>
        <div className={`slide_1 ${classes.slide}`} >
          <h3 style={{ paddingTop: '0.5em', }} className={classes.title}>{translations.slide_1.title}</h3>
          <h5 style={{ paddingTop: '0em', }}>{translations.slide_1.sub_title}</h5>
        </div>
      </div>
    </>
  )
}

CustomCarousel.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  slide: {
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    textShadow: 'rgb(0 0 0) 0px 0px 10px',
    color: 'white',
    '& h5': {
      top: '50px',
      textShadow: '1px 1px 8px #000000',
      fontSize: '23px',
      margin: '0em 1.5em',
      fontWeight: 'normal',
      lineHeight: '43px',
      paddingTop: '2em',
    },
  },
  title: {
    fontSize: '50px',
    textShadow: '1px 1px 10px #000000',
    lineHeight: '40px',
    animationName: 'example',
    animationDuration: '1s',
    margin: '0em 0.6em',
    fontWeight: 'normal',

  },
  dot: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginLeft: '1.5em',
  },
  dotContainer: {
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    bottom: '30px',
  },
  displayDescktop: {
    display: 'block',
  },
  displayMobile: {
    display: 'none',
  },
  '@media (max-width: 960px)': {
    slide: {
      backgroundPosition: '25%',
      height: '60vh',
      '& h5': {
        fontSize: '25px',
        textAlign: 'center',
        paddingTop: '1em',
      },
      '& h3': {
        fontSize: '28px',
        textAlign: 'center',
        paddingTop: '1em',
      },
    },
    title: {
      fontSize: '35px',
      padding: '1em 0em',
      textAlign: 'center',
    },
    displayDescktop: {
      display: 'none',
    },
    displayMobile: {
      display: 'block',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.home.carousel,
  }
}

export default connect(mapStateToProps)(CustomCarousel)
