import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import CustomDatePicker from '../atoms/CustomDatePicker'
import { connect } from 'react-redux'
import CustomSelect from '../atoms/CustomSelect'
import calculateStayConst from '../../utility/calucluateStayCost'

const StayCostCalculator = props => {
  const classes = useStyles()
  const { translations, contactLink, } = props
  const [formFields, setformFields] = useState({})

  const handleChange = (date, name) => {
    const newformFields = { ...formFields, [name]: date, }
    setformFields(newformFields)
  }
  return (
    <div className={classes.StayCostCalculator}>
      <div className={classes.calculatorContainer}>
        <div className={classes.title}>
          <span>{translations.costs_stay_calculator}</span>
        </div>
        <div className={classes.formFieldsContainer}>
          <CustomDatePicker name='checkInDate' date={formFields.checkInDate} setDate={handleChange} />
          <CustomDatePicker name='checkOutDate' date={formFields.checkOutDate} setDate={handleChange} />
          <CustomSelect name='adults' value={formFields.adults} onChange={handleChange}/>
          <CustomSelect name='children' value={formFields.children} onChange={handleChange}/>
          <div className={classes.result}> {calculateStayConst(formFields)} ZŁ </div>
          <a href={contactLink} className={classes.contact}> {translations.ask_for_the_date} </a>
        </div>
      </div>
    </div>
  )
}

StayCostCalculator.propTypes = {
  translations: PropTypes.object.isRequired,
  contactLink: PropTypes.string.isRequired,
}

const useStyles = createUseStyles({
  StayCostCalculator: {
    backgroundColor: '#2a2a2a',
  },
  calculatorContainer: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '2em 0em',
  },
  title: {
    backgroundColor: '#658C1D',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    justifyContent: 'center',
  },
  formFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& a': {
      textDecoration: 'none',
      paddingBottom: '0.3em',
    },
  },
  result: {
    marginTop: '0.5em',
    width: '33%',
    backgroundColor: '#658C1D',
    color: 'white',
    fontSize: '21px',
    textAlign: 'center',
    paddingTop: '0.3em',
    fontWeight: '700',
  },
  contact: {
    marginTop: '0.5em',
    width: '33%',
    backgroundColor: '#94d224',
    color: 'white',
    transition: 'all .3s',
    boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
    fontSize: '21px',
    textAlign: 'center',
    paddingTop: '0.3em',
    fontWeight: '700',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2a2a2a',
    },
  },
  '@media (max-width: 960px)': {
    result: {
      width: '100%',
    },
    contact: {
      width: '100%',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.home,
    contactLink: state.translations.translations.navbar.links.contact_link,
  }
}

export default connect(mapStateToProps)(StayCostCalculator)
