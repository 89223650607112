import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'

const CustomSelect = props => {
  const { value, onChange, name, translations, } = props
  const classes = useStyles()
  const options = [
    { label: 1, value: 1, },
    { label: 2, value: 2, },
    { label: 3, value: 3, },
    { label: 4, value: 4, },
    { label: 5, value: 5, },
    { label: 6, value: 6, },
    { label: 7, value: 7, },
    { label: 8, value: 8, }
  ]
  return (
    <Select
      className={classes.select}
      options={options}
      value={value}
      onChange={value => onChange(value, name)}
      placeholder={translations[name]}
  />
  )
}

const useStyles = createUseStyles({
  select: {
    width: '33%',
    marginTop: '0.5em',
  },
  '@media (max-width: 960px)': {
    select: {
      width: '100%',
    },
  },
})

CustomSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.home,
  }
}
export default connect(mapStateToProps)(CustomSelect)
