import React, { useRef } from 'react'
import useOnScreen from '../../utility/useOnScreen'
import { Suspense, lazy } from '@uploadcare/client-suspense'
const FacilitiesSection = lazy(() => import('../molecules/FacilitiesSection'))

const FacilitiesSectionWrapper = props => {
  const ref = useRef()
  const isSectionVisible = useOnScreen(ref)
  return (
    <>
    <div ref={ref}></div>
     <Suspense fallback={<div>...Loading</div>}>
        {isSectionVisible &&
          <FacilitiesSection />
        }
      </Suspense>
    </>
  )
}

export default FacilitiesSectionWrapper
