import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import StayCostCalculator from '../components/organisms/StayCostCalculator'
import CustomCarousel from '../components/molecules/CustomCarousel'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import SectionTitle from '../components/atoms/SectionTitle'
import IndexBottomSection from '../components/organisms/IndexBottomSection'
import FacilitiesSectionWrapper from '../components/organisms/FacilitiesSectionWrapper'
import SeoSection from "../components/molecules/SeoSection"
import ImageCarousel from "../components/atoms/ImageCarousel"
const IndexPage = (props) => {
  const { translations, path } = props
  const classes = useStyles()
  return (
  <Layout path={path}>
    <Seo
      title={translations.page_title}
      description={translations.page_description}
      />
    <CustomCarousel />
    <div className={classes.pageConainer}>
      <SectionTitle title={translations.accommodation} subtitle={translations.accommodation_disposal} color='#94d224' backgroundColor='#94d224' />
      <p className={classes.suitesDescription}>
        {translations.suites_description}
      </p>
    <div className={classes.imgSection}>
      <div className={classes.infoBox}>
        <h3>{translations.accommodation_for_every_pocket_title}</h3>
        <p> {translations.accommodation_for_every_pocket_desc} </p>
        <h3 style={{ marginTop: '3em', }}> {translations.great_location_title} </h3>
        <p> {translations.great_location_desc} </p>
      </div>
      <div className={classes.imgBox}>
        <div className={classes.displayMobile}>
          <StaticImage
            src="../images/domek-noclegi-dobczyce.webp"
            width={540}
            quality={15}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt={translations.image_alt}
            fadeIn={false}
            loading="eager"
          />
        </div>
        <div className={classes.displayDesktop}>
          <ImageCarousel page='index' alt={translations.image_alt} styles={{ height: '443px', }}/>
        </div>
      </div>
    </div>
    <FacilitiesSectionWrapper />
    <IndexBottomSection />
    <SeoSection />
    </div>
  </Layout>
  )
}

const useStyles = createUseStyles({
  pageConainer: {
    maxWidth: '1300px',
    margin: 'auto',
    padding: '1em',
  },
  suitesDescription: {
    textAlign: 'center',
    fontSize: '20px',
    marginTop: '2.2em',
  },
  imgSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '5em',
  },
  imgBox: {
    width: '50%',
  },
  displayMobile: {
    display: 'none',
  },
  displayDesktop: {
    display: 'block',
  },
  infoBox: {
    backgroundColor: 'white',
    width: '40%',
    height: '440px',
    padding: '1em',
    marginBottom: '3em',
    border: '2px dashed #94d224',
    '& h3': {
      color: '#94d224',
      fontSize: '19px',
      '&::after': {
        content: "''",
        display: 'block',
        width: '40px',
        height: '3px',
        marginTop: '0.5em',
        backgroundColor: '#94d224',
      },
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '22px',
    },
  },
  '@media(max-width: 960px)': {
    infoBox: {
      width: '100%',
      height: 'initial',
      '& p': {
        fontSize: '14px',
      },
    },
    displayMobile: {
      display: 'block',
    },
    imgBox: {
      width: '100%',
    },
    displayDesktop: {
      display: 'none',
    },
  },
})

IndexPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.home,
  }
}

export default connect(mapStateToProps)(IndexPage)
